var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"login login-1 d-flex flex-column flex-lg-row flex-column-fluid login-signin-on",style:({ backgroundImage: `url(${_vm.loginBg})` }),attrs:{"id":"kt_login"}},[_c('div',{staticClass:"login-aside d-flex flex-column flex-row-auto",staticStyle:{"background-color":"#422e87"}},[_c('div',{staticClass:"d-flex flex-column-auto flex-column pt-lg-40 pt-10"},[_c('div',{staticClass:"text-center mb-10"},[_c('img',{staticClass:"max-h-70px",attrs:{"src":require('@/assets/images/logo.svg'),"alt":"logo image"}})]),_c('h3',{staticClass:"font-weight-bolder text-center font-size-h4 font-size-h1-lg px-5",staticStyle:{"color":"#fff"}},[_vm._v(" Welcome To Squadio Dashboard ")])]),_c('div',{staticClass:"aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",style:({ backgroundImage: `url(${_vm.backgroundImage})` })})]),_c('div',{staticClass:"login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto w-100"},[_c('div',{staticClass:"d-flex flex-column-fluid flex-center"},[_c('div',{staticClass:"login-form login-signin"},[_c('form',{staticClass:"form",attrs:{"id":"kt_login_signin_form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v("Email")]),_c('div',{attrs:{"id":"example-input-group-1","label":"","label-for":"example-input-1"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.email),expression:"form.email",modifiers:{"trim":true}}],staticClass:"form-control h-auto py-7 px-6 rounded-lg form__input",class:{
                    'form__input--error':
                      _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
                  },attrs:{"type":"email","name":"email"},domProps:{"value":(_vm.form.email)},on:{"blur":[function($event){return _vm.$v.form.email.$touch()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value.trim())}}})]),(_vm.$v.form.email.$dirty && !_vm.$v.form.email.required)?_c('div',{staticClass:"form__error-label mt-2"},[_vm._v(" This Field Is Required ")]):_vm._e(),(_vm.$v.form.email.$dirty && !_vm.$v.form.email.email)?_c('div',{staticClass:"form__error-label mt-2"},[_vm._v(" Please Enter a Valid E-mail Address ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(0),_c('div',{attrs:{"id":"example-input-group-2","label":"","label-for":"example-input-2"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.password),expression:"form.password",modifiers:{"trim":true}}],staticClass:"form-control h-auto py-7 px-6 rounded-lg form__input",class:{
                    'form__input--error':
                      _vm.$v.form.password.$dirty && _vm.$v.form.password.$error,
                  },attrs:{"type":"password","name":"password","autocomplete":"off"},domProps:{"value":(_vm.form.password)},on:{"blur":[function($event){return _vm.$v.form.password.$touch()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value.trim())}}})]),(_vm.$v.form.password.$dirty && !_vm.$v.form.password.required)?_c('div',{staticClass:"form__error-label mt-2"},[_vm._v(" This Field Is Required ")]):_vm._e(),(_vm.$v.form.password.$dirty && !_vm.$v.form.password.minLength)?_c('div',{staticClass:"form__error-label mt-2"},[_vm._v(" This Field Should Be At Least "+_vm._s(_vm.passwordMinLength)+" Characters ")]):_vm._e()]),_c('div',{staticClass:"pb-lg-0 pb-5"},[_c('button',{staticClass:"btn btn-yellow font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3",class:{ 'spinner spinner-light spinner-right': _vm.loading },attrs:{"disabled":_vm.loading || !_vm.form.email || !_vm.form.password}},[_vm._v(" Sign In ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mt-n5"},[_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark pt-5"},[_vm._v("Password")])])
}]

export { render, staticRenderFns }