<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid login-signin-on"
      id="kt_login"
      :style="{ backgroundImage: `url(${loginBg})` }"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #422e87"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-10">
          <div class="text-center mb-10">
            <img
              :src="require('@/assets/images/logo.svg')"
              class="max-h-70px"
              alt="logo image"
            />
          </div>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg px-5"
            style="color: #fff"
          >
            Welcome To Squadio Dashboard
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto w-100"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              id="kt_login_signin_form"
              @submit.prevent="onSubmit"
            >
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control h-auto py-7 px-6 rounded-lg form__input"
                    type="email"
                    name="email"
                    @blur="$v.form.email.$touch()"
                    v-model.trim="form.email"
                    :class="{
                      'form__input--error':
                        $v.form.email.$dirty && $v.form.email.$error,
                    }"
                  />
                </div>
                <div
                  class="form__error-label mt-2"
                  v-if="$v.form.email.$dirty && !$v.form.email.required"
                >
                  This Field Is Required
                </div>
                <div
                  class="form__error-label mt-2"
                  v-if="$v.form.email.$dirty && !$v.form.email.email"
                >
                  Please Enter a Valid E-mail Address
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control h-auto py-7 px-6 rounded-lg form__input"
                    type="password"
                    name="password"
                    @blur="$v.form.password.$touch()"
                    v-model.trim="form.password"
                    autocomplete="off"
                    :class="{
                      'form__input--error':
                        $v.form.password.$dirty && $v.form.password.$error,
                    }"
                  />
                </div>
                <div
                  class="form__error-label mt-2"
                  v-if="$v.form.password.$dirty && !$v.form.password.required"
                >
                  This Field Is Required
                </div>
                <div
                  class="form__error-label mt-2"
                  v-if="$v.form.password.$dirty && !$v.form.password.minLength"
                >
                  This Field Should Be At Least
                  {{ passwordMinLength }} Characters
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  class="btn btn-yellow font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  :class="{ 'spinner spinner-light spinner-right': loading }"
                  :disabled="loading || !form.email || !form.password"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// test deployment
const passwordMinLength = 3;
import { mapGetters } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  name: "login-1",
  mixins: [validationMixin],
  data() {
    return {
      passwordMinLength,
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(passwordMinLength),
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      isAuthenticated: "auth/isAuthUser",
    }),
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
    loginBg() {
      return require("@/assets/images/login-bg.svg");
    },
  },
  created() {
    // check if current user is authenticated
    if (this.isAuthenticated) {
      this.$router.push({ name: "talents-list" });
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch("auth/login", this.form).then(() => {
        this.$router.push(
          this.$route.query.redirect || { name: "talents-list" }
        );
      });
    },
  },
};
</script>

<style lang="scss">
.login.login-1 {
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}
</style>
